$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';
@import 'styles/constants';

.auth-modal {
  background-color: $white;
  overflow-x: auto;
  padding: $spacer-m $spacer-s;
  padding-bottom: 0;

  @include breakpoint(tablet) {
    border-radius: 0 !important;
    height: 621px;
    left: 50%;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 800px !important;
  }

  &--sign-up-form {
    @include breakpoint(tablet) {
      height: 675px;
    }
  }

  &--reset-pw {
    @include breakpoint(tablet) {
      height: auto;
      width: 600px !important;
    }
  }

  &__social-container {
    padding-bottom: $spacer-s;
  }

  .auth-modal.auth-modal--reset-pw {
    @include breakpoint(tablet) {
      width: 600px !important;
    }
  }

  .auth-modal__close-button {
    @include breakpoint(tablet) {
      @include z-index('x');

      color: $white;

      &--pw-reset {
        color: $black;
      }
    }
  }

  .auth-modal__body {
    @include breakpoint(tablet) {
      display: flex;
    }
  }

  .auth-modal__body-content {
    @include breakpoint(tablet) {
      padding: $spacer-m;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: calc(50% - 64px);

      &--pw-reset {
        width: 100%;
      }
    }

    @include breakpoint(xlarge-desktop) {
      margin-top: 20px;
    }

    &--pw-reset {
      @include breakpoint(tablet) {
        padding: 32px;
        position: relative;
        top: 0;
        transform: none;
      }
    }
  }

  .auth-modal__header {
    @include z-index('navbar');

    align-items: center;
    background: $white;
    display: flex;
    height: $navbar-height;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
  }

  .auth-modal__icon {
    @include z-index('x');

    padding: $spacer-xxs;
    position: absolute;
    right: $spacer-xs;
    top: $spacer-xs;
  }

  .auth-modal__logo {
    @include z-index('x');

    display: flex;
    justify-content: center;
    width: 100%;
  }

  .auth-modal__image-container {
    height: 100%;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    width: 50%;
  }

  .auth-modal__image {
    height: 100% !important; // Overwrite a default `auto` height added inline by Next
    object-fit: cover;
  }

  .auth-modal__copy {
    color: $grey-500;
    margin: 0 auto;
    max-width: 500px;
    padding: 5px 18px 10px;
    text-align: center;

    @include breakpoint(tablet) {
      padding: 0;
    }

    &--full-screen {
      padding-bottom: 0;
    }
  }

  .auth-modal__copy-header {
    @include font($display-small);

    color: $grey-500;
    font-family: everlane-font('sans--bold');
    margin-bottom: 2px;

    &--new-user {
      @include font($display-medium);

      color: $grey-500;
      line-height: 1.3em;
    }

    &--pw-reset {
      margin-bottom: $spacer-s;
    }
  }

  .auth-modal__copy-subheader {
    @include font($body-medium);

    color: $black;
    padding-bottom: 10px;

    &--center {
      text-align: center;
    }

    &--new-user {
      color: $grey-600;
      padding-top: 15px;
    }
  }

  .auth-modal__everlane-logo {
    padding-bottom: 60px;
    padding-top: 10px;

    @include breakpoint(tablet) {
      padding: 0 0 $spacer-m;
    }

    path {
      fill: $grey-400;
    }
  }

  .auth-modal__continue-shopping {
    text-align: center;

    &--button {
      margin-bottom: 30px;
      margin-top: 10px;
      text-decoration: underline;
    }
  }

  .auth-modal__form-wrapper {
    padding: 10px 0;
    padding-bottom: 20px;
  }

  .auth-modal__copy-link {
    border: none;
    color: $black;
    text-decoration: underline;

    &--signup {
      margin: 0 6px;
      padding: 0;
    }
  }

  .auth-modal__reset-password-link-wrapper {
    padding-bottom: 20px;
    padding-top: 5px;
    text-align: center;
  }

  .auth-modal__reset-password-link {
    padding: 0;
  }

  .auth-modal__generic-error {
    background: $white;
    color: $red-500;
    margin: 10px;
    margin-bottom: 0;
    padding: 0;
    text-align: center;
  }

  .auth-modal__switch-to-login-button {
    margin: 34px 0;
    width: 100%;
  }

  .auth-modal__copy-link--signin {
    padding: 0;
    padding-bottom: 10px;
    padding-left: 5px;
    text-decoration: underline;
  }
}
