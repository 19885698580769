$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';

.email-form {
  overflow: hidden;
  padding-top: 10px;

  .email-form__label {
    @include font($body-small);

    color: $grey-400;
  }

  .email-form__input {
    @include font($body-medium);

    background: $grey-100;
    border: none;
    box-sizing: border-box;
    color: $grey-500;
    line-height: 22px;
    outline: none;
    padding: 12px;
    width: 100%;
  }

  .email-form__input--error {
    border: 1px solid $red-500;
  }

  .email-form__submit-button {
    margin: 12px 0;
    padding: 0;
    width: 100%;
  }

  .email-form__error {
    color: $red-500;
    margin-top: 5px;
  }
}
