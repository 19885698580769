$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

.product-image {
  background-color: $grey-100;
  display: block;
  overflow: hidden;
  position: relative;

  &-container {
    margin-bottom: $spacer-xs;
    position: relative;
  }

  &__container {
    display: block;
    height: 0;
    padding-bottom: 125%;
    position: relative;

    &--square {
      padding-bottom: 100%;
    }
  }

  &__image-container {
    height: 100%;
    position: absolute;
    width: 100%;
  }

  &__image {
    display: block;
    height: 100%;
    transition: opacity 150ms linear;
  }

  &__image--hover {
    height: 100%;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
  }

  &__loading-image {
    display: block;
    position: absolute;
    transition: opacity 150ms linear;
    width: 100%;
  }

  &__size-selector {
    bottom: 0;
    cursor: pointer;
    position: absolute;
    text-align: center;
    width: 100%;
  }

  &--sold-out::after {
    @extend %product-image--overlay;
    @extend %product-image--overlay-text;

    content: 'Sold Out';
    text-align: center;
  }

  &--waitlist::after {
    @extend %product-image--overlay;
    @extend %product-image--overlay-text;

    content: 'Waitlist';
    text-align: center;
  }
}

%product-image--overlay {
  background: rgba($grey-500, 0.3);
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

%product-image--overlay-text {
  @include font($body-small);

  color: $white;
  letter-spacing: 0.7px;
  padding-top: 49%;
  text-transform: uppercase;
}
