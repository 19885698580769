$use_maison: true;
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/fonts';

.privacy-policy {
  @include font($body-small);

  color: $grey-400;
  padding: 16px 0;
}

.privacy-policy__link {
  color: $grey-400;
}
