$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

.shipping-progress-bar,
.gwp-progress-bar {
  margin-bottom: $spacer-sm;
  width: 100.08%; // fudge it a bit to align with the closing x

  @include breakpoint(desktop) {
    margin-bottom: 0;
  }
}

.shipping-progress-bar__text,
.gwp-progress-bar__text {
  @include font($body-small);

  align-items: center;
  display: flex;
  padding-bottom: $spacer-xs;

  &--no-bar {
    margin-bottom: 10px;
  }

  b {
    @include font($body-small-bold);
  }
}

.amount__to-avail-free-gwp {
  @include font($body-medium-bold);
}

