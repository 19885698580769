$use_maison: true;
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/mixins';

.everlane-modal {
  background-color: $white;
  bottom: 0;
  box-sizing: border-box;
  height: 100%;
  max-width: 800px;
  outline: none;
  overflow-y: hidden;
  padding: 16px;
  position: absolute;
  width: 100%;

  @include breakpoint(tablet) {
    height: unset;
    margin: 0 auto;
    min-height: 200px;
    position: relative;
    top: 25%;
    width: 50%;
  }
}

.everlane-modal__overlay {
  @include z-index(modal);

  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
}

.everlane-modal__close-button {
  @include z-index('x');

  position: absolute;
  right: 16px;
  transform: scale(1.25);

  @include breakpoint(tablet) {
    cursor: pointer;
    float: right;
    position: relative;
  }
}

.everlane-modal__header {
  @include font($body-large-bold);

  padding-top: 32px;
  text-align: center;

  @include breakpoint(tablet) {
    padding: 24px 16px 12px;
    text-align: left;
  }
}
