$use_maison: true;
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/spacers';

.billing {
  background: $white;
  border: none;
  padding: $spacer-sm;
  position: relative;
  
  @include breakpoint(desktop) {
    border-bottom: 1px solid $grey-300;
    padding: 40px 88px;
  }
}

.billing__header {
  display: flex;
}

.billing__header-edit-button {
  letter-spacing: 0;
  text-transform: none;
}

.billing__header-secure-message {
  @include font($body-small);

  margin: -10px 0 $spacer-m 40px;

  @include breakpoint(tablet) {
    @include font($body-medium);
  }

  &--closed {
    @include breakpoint(tablet) {
      margin: -18px 0 $spacer-m 40px;
    }
  }
}

.billing__payment-method-wrapper {
  margin: 12px 0;
}

.billing__default-payment-method {
  margin: 0 0 $spacer-sm 40px;

  &--new-user {
    margin: 0 0 40px 40px;
  }
}

.billing__show-all-button-wrapper {
  @include font($body-medium-bold);

  display: flex;
  justify-content: flex-end;
  margin: $spacer-s 0 0;
}

.billing__show-all-button {
  @include font($body-small);

  color: $black;
  cursor: pointer;
  text-decoration: underline;
}

.billing__new-card-separator {
  margin-bottom: $spacer-s;
  padding-top: 0;
}

.billing__new-card-billing-separator {
  margin: $spacer-s 0;
}

.billing__store-credit-msg {
  @include font($body-medium);

  color: $grey-400;
  padding-left: 40px;

  @include breakpoint(tablet) {
    padding-left: 36px;
  }

  &--copy {
    padding: $spacer-xs $spacer-xxs;
  }
}

.billing__error-banner {
  @include font($body-small);

  border: 1px solid $red-200;
  margin-top: $spacer-s;
}

.merchant-badge {
  margin-top: $spacer-sm;

  &__copy,
  &__link {
    @include font($heading-xsmall);

    color: $grey-400;
  }

  &__copy {
    margin: 0 0 $spacer-xs;
  }

  &__link {
    text-decoration: none;
  }

  &--reach > div {
    width: 62px;
  }

  &--stripe {
    background-image: url(static_image_url('i/stripe-badge-transparent.png'));
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 110px;
    width: 100%;
  }

  img {
    height: 21px;
    left: -4px;
    opacity: 0.25;
    position: relative;
    width: 62px;
  }
}
