$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';

.policy-and-terms {
  @include font($body-small);

  color: $grey-400;
  line-height: 18px;
  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: 15px;
  max-width: 300px;
  text-align: center;
}

.policy-and-terms__link {
  color: $grey-500;
  text-decoration: underline;
}
