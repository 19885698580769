$use_maison: true;
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/spacers';

.shop-the-look {
  &__container {
    margin-bottom: $spacer-l;
  }

  &__header {
    @include font($body-small-bold);

    margin-bottom: $spacer-xs;
    text-transform: uppercase;
  }

  &__list {
    display: flex;
  }

  &__list-item {
    flex-basis: 96px;

    &__button {
      padding: 0;

      div {
        z-index: 1;
      }

      img {
        z-index: -1;
      }

      &:hover div {
        box-shadow: inset 0 0 0 1px #c8c0b8;
      }

      &--active {
        box-shadow: inset 0 0 0 1px $black;
      }
    }
  }
}
