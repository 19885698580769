$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

.line-items-header {
  @include font($body-xsmall);

  letter-spacing: 1px;
  line-height: 12px;
  margin: $spacer-sm 0;
  text-transform: uppercase;
}

.line-items {
  border-bottom: 1px solid $grey-300;
  padding-bottom: 20px;

  &:last-of-type {
    border: 0;
    padding-bottom: 0;
  }
}

ul.reward-items {
  border-bottom: none;
  margin-right: $spacer-s;

  b {
    @include font($body-small-bold);

    @include breakpoint(desktop) {
      @include font($body-medium-bold);
    }
  }
}

.line-item {
  display: flex;
  margin-bottom: $spacer-s;
  min-height: 100px;
  position: relative;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
}

.reward-item {
  border: 1px solid $grey-300;
  display: flex;
  margin-bottom: 26px;
  margin-top: $spacer-xs;
  min-height: 100px;
  padding: $spacer-xs;
  position: relative;
  width: 100%;

  @include breakpoint(desktop) {
    margin-bottom: $spacer-s;
  }

  &--checkout {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.line-item__image-conatiner,
.reward-item__image-conatiner {
  width: 102px;

  @include breakpoint(desktop) {
    width: 160px;
  }
}

.line-item__content,
.reward-item__content {
  @include font($body-small);

  padding-left: $spacer-xs;
  position: relative;
  width: 100%;
}

.line-item__title-link,
.reward-item__title-link {
  color: $black;
  text-decoration: none;
}

.line-item__title,
.reward-item__title {
  @include font($body-small);

  line-height: 20px;

  @include breakpoint(desktop) {
    @include font($body-medium);

    line-height: 20px;
  }
}

.line-item__details,
.reward-item__details {
  @include font($body-small);

  color: $grey-400;
  padding: 0;
}

.line-item__actions {
  padding-left: 6px;
  position: relative;
  text-align: right;
}

.line-item__remove-container {
  box-shadow: none;
  cursor: pointer;
  padding: 5px;
  position: relative;
  right: -5px;
  top: -15px;

  &:hover,
  &:focus {
    background-color: transparent !important;
    box-shadow: none !important;

    svg {
      fill: transparent !important;
      stroke: $red-500;
    }
  }
}

.line-item__quantity-button-container {
  margin-top: $spacer-sm;
}

.line-item__quantity-button {
  @include font($body-medium);

  align-items: center;
  border: 1px solid $grey-300;
  bottom: 0;
  display: flex;
  height: $spacer-m;
  justify-content: space-between;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;

  @include breakpoint(desktop) {
    @include font($body-small);
  }

  p {
    display: block;
    flex: 0 1 $spacer-sm;
    text-align: center;
    width: $spacer-m;
  }

  button {
    box-shadow: none;
    height: $spacer-m !important;
    width: 28px !important;
  }
}

button.line-item__quantity-button-el {
  padding: $spacer-xs;
}

.line-item__quantity-icon {
  position: relative;
  stroke-width: 3px;
}

.line-item__static-quantity {
  padding-left: 6px;
}

.line-item__giftcard-link {
  @include font($body-small);

  text-decoration: underline;

  @include breakpoint(tablet) {
    cursor: pointer;
  }
}

.line-item__error-message {
  @include font($body-small-bold);

  color: $red-500;
  padding-top: $spacer-xs;
}

.line-item__restock-message {
  @include font($body-xsmall);

  align-items: center;
  background: $grey-100;
  color: $grey-600;
  display: flex;
  height: $spacer-m;
  justify-content: center;
  margin-bottom: $spacer-xs;
}

.line-item__price-container {
  bottom: 0;
  position: absolute;
}

.line-item__original-price {
  @include font($body-small-bold);

  color: $black;

  &--striked {
    color: $grey-400;
    text-decoration: line-through;
  }
}

.line-item__discount-price {
  @include font($body-small-bold);

  color: $black;
  margin-left: $spacer-xxs;

  &__label {
    @include font($body-small);

    color: $red-500;
  }
}

.bundle-line-items {
  margin-bottom: $spacer-sm !important;

  .bundlable-line-items:not(:first-child) {
    padding-top: $spacer-s;
  }
}

.bundle-item {
  padding-bottom: $spacer-m;

  .bundle-item__header-container {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  }

  .bundle-item__label {
    @include font($body-small);

    color: $black;
    text-transform: uppercase;
  }

  .bundle-item__threshold-count {
    @include font($body-small);

    color: $black;
    text-transform: uppercase;
  }

  .bundle-item__progress-container {
    padding: $spacer-xs 0;
  }

  .bundle-item__how-to-message {
    @include font($body-small);
    
    color: #737373;
  }
}

.line-item__quantity-update-shim {
  @include z-index('loading-overlay');

  background: rgba(255, 255, 255, 0.7);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.line-item__spinner-container,
.loading-spinner-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;

  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global {
    .everlane-loading-spinner {
      margin-bottom: $spacer-m;
      margin-left: 50%;
      transform: translateX(-50%);

      @include breakpoint(desktop) {
        margin-bottom: $spacer-ml;
        transform: translateX(-100%);
      }
    }
  }
}
