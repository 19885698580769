$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

$navbar-height: 40px;

.navbar {
  &-container {
    @include z-index(navbar);

    background: $white;
    left: 0;
    max-width: 100%;
    overflow-x: hidden;
    position: sticky;
    top: 0;
    width: 100%;

    @include breakpoint(desktop) {
      overflow-x: visible;
    }

    @media (min-width: 1025px) {
      &--minimal {
        border-bottom: none;
      }
    }

    /* stylelint-disable selector-pseudo-class-no-unknown */
    :global {
      .everlane-floating-box {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

        &__footer {
          margin-top: 20px;
        }
      }
    }

    &--sticky-banner {
      top: $spacer-m;
    }

    &--subnav-hidden {
      height: 40px;
    }
  }

  &__icon-button {
    height: 40px;
    width: 40px;

    svg {
      margin-top: $spacer-xxs;
      stroke-width: 1.25px;
    }
  }

  &__link {
    cursor: pointer;
    display: block;
    text-transform: uppercase;

    @include breakpoint(desktop) {
      padding: 0;
    }

    &-wrapper {
      display: block;
      margin-right: 0;

      &--active {
        border-bottom: 2px solid $black;
      }

      &--logo {
        height: inherit;
        margin-right: 0;
      }

      &--cart {
        margin-right: 0;
      }
    }

    &--logo {
      @include breakpoint(desktop) {
        height: 14px;
      }
    }
  }

  &__category {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 40px;
    justify-content: center;
    text-transform: uppercase;

    &--active {
      a {
        color: $black;
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
  }

  &__wrapper {
    align-items: center;
    display: flex;
    height: 40px;
    padding: 0 $spacer-xxs;
    position: relative;
    text-align: center;

    @include breakpoint(desktop) {
      border-bottom: 0.5px solid #E0DFDF;
      height: $navbar-height;
      padding: 0 $spacer-s;

      &--minimal {
        border-bottom: none;
      }
    }

    &--minimal {
      .navbar__menu-left {
        display: none;
      }
    }

    &-container {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      height: 100%;
      justify-content: center;
      margin: auto;
      position: relative;
      width: 100%;
    }

    .navbar__menu-left {
      height: 100%;
      left: 0;
      min-width: 30px;
      position: absolute;
      text-align: left;
    }

    .navbar__menu-center {
      display: flex;
      justify-content: center;
      width: 100%;

      @media (max-width: 1024px) {
        padding-top: 0;

        &--minimal {
          padding-top: 6px;
          width: 100%;
        }
      }

      @include breakpoint(desktop) {
        width: 33.3%;

        &--minimal {
          width: 100%;
        }
      }
    }

    &__icon-wrapper {
      align-items: center;
      display: flex;
      height: 100%;
  
      svg,
      svg path {
        color: $black;
        stroke-width: 1px;
      }
    }

    .navbar__menu-right {
      align-items: center;
      bottom: $spacer-xs;
      display: flex;
      flex: 1 0 0;
      gap: 20px;
      justify-content: flex-end;
      padding-right: $spacer-s;
      position: absolute;
      right: 0;

      @include breakpoint(desktop) {
        bottom: auto;
        padding-right: 0;
      }

      a, .navbar__link {
        color: $black;
        text-decoration: none;

        &:hover {
          color: $black;
          text-decoration: underline;
          text-underline-offset: 2px;
        }
      }

      svg {
        color: $black;
        height: $spacer-s;
        stroke-width: 1.25px;
        width: $spacer-s;
      }
    }
  }

  &__categories {
    align-items: center;
    display: flex;
    flex: 1 0 0;
    gap: 20px;
  }

  &__category-link {
    @include font($body-small);

    color: $black;
    text-decoration: none;

    &:hover {
      color: $black;
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }
}

svg.everlane-logo path,
svg.ellipsis-icon {
  fill: $black;
}

svg.ellipsis-icon {
  height: 18px;
  transform: rotate(90deg);

  path {
    stroke: transparent;
  }
}
