$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';

.google-connect__cta {
  @include font($button);

  align-items: center;
  display: flex;
  text-transform: uppercase;
}

.google-connect__cta-image {
  height: 18px;
  margin-right: 8px;
  width: 18px;

  img {
    background-color: transparent;
  }

  &--icon-only {
    margin-right: 0;
  }
}

.google-connect__cta-text {
  color: $black;
}
