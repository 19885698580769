$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';

.pagination {
  display: flex;
}

.pagination__button {
  @include font($body-small-bold);

  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  margin-right: 8px;
  padding-top: 3px;
  width: 24px;

  &--selected {
    background-color: $blue-500;
    color: $white;
  }

  // Hacky way to target Safari
  // stylelint-disable number-max-precision
  @media not all and (min-resolution: 0.001dpcm) {
    padding: 2px;
  }
}
