$use_maison: true;
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/mixins';
@import 'styles/spacers';

.carbon-offset__wrapper {
  background: $white;
  display: flex;
  justify-content: space-between;
  padding: 0 0 $spacer-sm;

  &--hidden {
    display: none;
  }
}

.carbon-offset__content {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding-left: $spacer-s;
}

.carbon-offset__checkbox {
  align-items: flex-start;
  width: auto !important;
}

.carbon-offset__message {
  @include font($body-medium-bold);

  color: $green-500;
  flex-basis: 100%;
  line-height: 20px;
  margin-bottom: $spacer-xxs;

  @include breakpoint(desktop) {
    @include font($body-large-bold);
  }

  sub {
    font-size: smaller;
    font-weight: normal;
    vertical-align: sub;
  }
}

.carbon-offset__caption-wrapper {
  display: flex;
}

.carbon-offset__learn-more {
  @include font($body-small);

  background: none;
  border: 0;
  color: $grey-400;
  display: block;
  padding: 0;
  position: relative;

  &:focus {
    outline: 0;
  }

  svg {
    bottom: 2px;
    height: $spacer-s;
    margin-left: $spacer-xxs;
    position: absolute;
    width: $spacer-s;
  }
}

.carbon-offset__leaf {
  height: 20px;
  width: 20px;

  path {
    stroke: $green-500;
  }
}

.carbon-offset-modal__overlay {
  @include breakpoint(mobile) {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.carbon-offset-modal {
  @include breakpoint(mobile) {
    background: transparent;
    height: 400px;
    position: relative;
    width: unset;

    svg {
      margin: $spacer-l $spacer-xs 0 -8px;
    }
  }
}

.carbon-offset-modal__message-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.carbon-offset-modal__message {
  @include font($body-small);

  background: $white;
  padding: $spacer-s;
  text-align: center;
}
