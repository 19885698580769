$use_maison: true;
@import 'styles/colors';

.progress-bar {
  background-color: $grey-200;
  display: block;
  height: 4px;

  &--green {
    background: $green-200;
  }

  &--mini {
    height: 2px;
  }
}

.progress-bar--filled {
  background-color: $grey-400;
  height: 4px;
  transition: width 1s;
}

.progress-bar--filled-green {
  background-color: $green-500;
}

.progress-bar--filled-mini {
  height: 2px;
}