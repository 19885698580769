$use_maison: true;
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/mixins';
@import 'styles/spacers';

.signup-for-email {
  display: flex;
  flex-direction: column;
  padding: $spacer-sm 0;
}

.signup-for-email__mail-icon {
  bottom: 2px;
  height: $spacer-sm;
  position: relative;
  width: $spacer-sm;
}

.signup-for-email__checkbox-label-ctr {
  flex: 1;
  padding: 0 $spacer-xs;
}

.signup-for-email__checkbox-label {
  @include font($body-small);

  @include breakpoint(desktop) {
    @include font($body-medium);
  }
}

.signup-for-email__wrapper {
  display: flex;
  justify-content: space-between;
}

.signup-for-email__checkbox {
  align-items: flex-start;
}
