$use_maison: true;
@import 'styles/colors';
@import 'styles/spacers';

.product-group-tile__link {
  text-decoration: none;
}

.product-group-image__container {
  margin-bottom: $spacer-xs;
  position: relative;
}

.product-group-image {
  width: 100%;
}

.product-group-tile__image--minimal {
  max-height: 100px;
  width: 80px;
}

.product-group-tile__carousel-pips {
  bottom: 8px !important;
  display: flex;
  left: unset;
  max-height: 2px;
  right: 8px !important;
  transform: unset;
  width: 47px;
}

.product-group-tile__carousel-pips__pip {
  height: 2px;
}

.product-group-tile__carousel-pips__pip--active {
  background-color: $grey-400;
}

@keyframes swipeHint {
  0% {
    transform: translateX(0);
  }

  50%,
  80% {
    transform: translateX(var(--swipe-distance));
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes swipeHintSecondImg {
  0% {
    transform: translateX(0);
  }

  50%,
  80% {
    transform: translateX(-55px);
  }

  100% {
    transform: translateX(0);
  }
}

.swipe-animation {
  --swipe-distance: -30px;

  animation: swipeHint 1.4s ease;
}

.swipe-animation-second-img {
  --swipe-distance: 50px;

  animation: swipeHintSecondImg 1.37s ease;
}
