$use_maison: true;
@import 'styles/mixins';

.product-gallery {
  height: 100%;
  width: 100%;

  &__overlay {
    max-height: 100vh;
    overflow-y: scroll;
    scrollbar-color: black transparent;
    scrollbar-width: thin;
  }
}
