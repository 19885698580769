$use_maison: true;
/* stylelint-disable no-descending-specificity */

@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

.desktop-nav__container {
  background: $white;
  border-bottom: 0.5px solid #E0DFDF;
  display: flex;
  height: $spacer-m;
  justify-content: space-around;
  opacity: 1;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 150ms ease-in, visibility 150ms;
  visibility: visible;

  &--hidden {
    opacity: 0;
    transition: max-height 0.5s ease-in-out, opacity 400ms ease-out, visibility 400ms;
    visibility: hidden;
  }

  .desktop-nav__categories {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 20px;
    justify-content: center;
    padding: 0 16px;

    .desktop-nav__category {
      @include font($body-small);

      align-items: center;
      display: flex;
      flex-direction: column;
      height: $spacer-m;
      justify-content: center;
      text-transform: uppercase;
      white-space: nowrap;
      
      .desktop-nav__category-link {
        align-items: center;
        color: $black;
        display: flex;
        height: 32px;
        justify-content: center;
      }

      &--active {
        .desktop-nav__category-link {
          color: $black;
          text-decoration: underline;
          text-underline-offset: 2px;
        }
      }
    }


    .desktop-nav__category-link {
      color: $black;
      display: block;
      text-decoration: none;
    }
  }
}

.category-dropdown__veil {
  background-color: $black;
  height: 100vh;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 74px;
  visibility: hidden;
  width: 100%;
  z-index: -1;

  &,
  &[hidden] {
    display: flex;
  }

  &:not([hidden]) {
    opacity: 0.5;
    transition: opacity 300ms ease-out 200ms, visibility 0ms;
    visibility: visible;
  }

  &[hidden] {
    opacity: 0;
    transition: opacity 200ms ease-out 300ms, visibility 0ms ease 350ms;
    visibility: hidden;
  }
}
