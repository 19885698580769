$use_maison: true;
@import 'styles/mixins';
@import 'styles/spacers';

.product-group-tile {
  cursor: pointer;
  margin: 0 0 24px;

  &__details-wrapper {
    padding: 0 $spacer-xs;

    @include breakpoint(tablet) {
      min-height: 85px;
      padding: 0;
    }
  }
}

.product-group-tile__link {
  text-decoration: none;
}

.product-group-tile--collection {
  margin: 0;

  .product-group-tile__details-wrapper {
    height: fit-content;
    min-height: unset;
  }
}

.product-group-tile--minimal {
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  gap: $spacer-s;
  justify-content: space-between;
  margin: 0;
  max-height: 100px;

  .product-group-tile__details-wrapper {
    display: flex;
    flex-direction: row;
    height: fit-content;
    justify-content: space-between;
    min-height: unset;
  }
}

.product-group-tile--placehoder {
  align-items: center;
  display: flex;
  height: 0;
  padding-bottom: 125%;
  position: relative;
}

.product-group-tile__text {
  align-items: center;
  display: flex;
  height: 100%;
  line-height: 1.4em;
  position: absolute;
  top: 0;
}
