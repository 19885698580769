$use_maison: true;
@import 'styles/colors';

.social-connect {
  display: flex;
  text-align: center;
}

.social-connect__button {
  align-items: center;
  box-shadow: 0 0 0 1px $grey-300;
  display: flex;
  justify-content: center;
  width: calc(50% - 4px);

  &--icon-only {
    height: 48px;
    width: 48px;
  }

  &:first-of-type {
    margin-right: 8px;
  }
}
