$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

.size-selector {
  @include font($body-medium);

  align-items: center;
  background: $white;
  box-sizing: border-box;
  display: flex;
  height: auto;
  justify-content: center;
  margin: 10px;
  min-height: 40px;
  text-transform: uppercase;

  &-enter {
    min-height: 40px;

    li {
      opacity: 0;
    }
  }

  &-enter-active {
    max-height: 500px;
    transition: all 420ms;

    li {
      opacity: 1;
      transition: all 420ms;
    }
  }

  &__size-container {
    @include font($body-small);

    letter-spacing: 0.1em !important;
    line-height: 1 !important;
    margin: 0 auto;
  }

  &__size-container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    min-height: 40px;
    padding: $spacer-xs;
  }

  &__quick-add-container {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;

    & > svg {
      position: absolute;
      right: $spacer-s;
    }
  }

  &__quick-add-cta {
    @include font($body-small);

    cursor: pointer;
    letter-spacing: 0.1em !important;
    line-height: 1 !important;
    margin: 0 auto;
  }

  &__size {
    color: $grey-500;
    display: inline-block;
    min-height: 40px;
    overflow: inherit !important;

    // Overwrite a general selector on collection page
    &:hover {
      background: $grey-100;
    }
  }

  &__size--active {
    background: $grey-200;
    box-shadow: 0 0 0 1px $grey-300;
  }

  &__size--sold-out {
    color: $grey-300;
    pointer-events: none;
    text-decoration: line-through;

    &:hover {
      background: transparent;
    }
  }

  &__link {
    @include font($body-small);

    background: transparent;
    border: 0;
    color: inherit;
    cursor: pointer;
    display: inherit;
    height: 100%;
    letter-spacing: 0.1em !important;
    line-height: 1 !important;
    min-width: 39px;
    padding: $spacer-xs;
  }
}
