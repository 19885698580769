$use_maison: true;
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/spacers';

.product-sold-out {
  text-align: center;

  .box {
    background-color: $grey-100;
    border: none;
    margin: $spacer-s;
    padding: 20px $spacer-s;

    @include breakpoint(desktop) {
      margin: 0;
      padding: 20px;
    }
  }

  .heading {
    @include font($body-medium-bold);

    margin-bottom: $spacer-xs;
  }

  .info {
    @include font($body-small);

    color: $grey-400;
  }
}
