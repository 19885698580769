$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';

.read-more--button {
  @include font($body-medium-bold);

  bottom: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
}

.read-more--button-close {
  @include font($body-medium-bold);

  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
}
