$use_maison: true;
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/spacers';

div.shipping-address-modal {
  overflow-y: auto;
  padding: $spacer-sm $spacer-s;

  @include breakpoint(tablet) {
    height: 75%;
    min-height: 600px;
    top: 12.5%;
  }

  h2 {
    text-align: center;
  }
}

.shipping-address-modal__content {
  margin: $spacer-s 0;
}
